import React, { useState, useEffect } from "react";
import { navigate, graphql } from "gatsby";
import SEO from "../components/SEO/seo";
import Layout from "../components/Layout";
import HomePage from "../components/Home/home";
import Agegate from "../components/Agegate/agegateNew";

const IndexPage = ({ data, location }) => {
  const [pocId, setPocId] = useState("");


  const promotionsData = data?.allPromotions?.nodes;
  const categoriesData = data?.allCategories?.nodes;
  const brandsData = data?.allBrands?.nodes;
  const termsData = data?.allTerms?.nodes;
  const pocsData = data?.allContacts?.nodes ?? [];

  // useEffect(() => {
  //   let local = localStorage.getItem("age");
  //   let session = sessionStorage.getItem("age");
  //   if (!session && !local) navigate("/agegate", { state: { newPath: "/" } });
  // }, []);




  return (
    <>
      <SEO title="On premise" />
      <Layout allBrands={brandsData} allTerms={termsData}>
          <Agegate location={location}/>
        {/*<HomePage*/}
        {/*  allCategories={categoriesData}*/}
        {/*  allPromotions={promotionsData}*/}
        {/*  allBrands={brandsData}*/}
        {/*  allTerms={termsData}*/}
        {/*  pocsData={pocsData}*/}
        {/*/>*/}
      </Layout>
    </>
  );
};

export default IndexPage;

export const data = graphql`
  query IndexQuery {
    allPromotions {
      nodes {
        body
        brand
        active
        img
        id
        type
        url
        category
        order
        region
        hours
        days
      }
    }

    allCategories {
      nodes {
        name
        order
      }
    }

    allBrands {
      nodes {
        active
        bannerTitle
        color
        backgroundColor
        imgBanner
        imgBrand
        name
      }
    }
    allTerms {
      nodes {
        brand
        consumers
        shops
      }
    }
  }
`;
